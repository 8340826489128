/* 
 * To change this template, choose Tools | Templates
 * and open the template in the editor.
 */


function standardAjaxInfo(dataset, options) {
    var flan = $.extend(true, {url: "info.srv", type: "get", data: {dataset: dataset}}, options);
    return wrapXhrForStandard($.ajax(flan));
}

/** Performs an ajax action, returns a Promise.
 * This rejects if the ajax fails OR if the returned JSON has an
 * error code set
 * This method adds on its own standard fail handler (TODO, make better!, Want to do lazily!)
 * 
 * @param {object} options As passed to $.ajax()
 * @param {string} url URL to post to (optional).
 * @returns {Promise} Wrapped promise from $.ajax()
 */
function standardAjaxPost(options, url) {
    $("body").addClass("busy");
    var flan = {url: url, type: "post", data: $.extend(true, {csrf: $("input[name=csrf]").val()}, options)};
    return wrapXhrForStandard($.ajax(flan)).fail(function(error, message) {
        $.jGrowl(error + (message === error ? "" : "\n" + message), {
            sticky: true,
            header: options.data.action + " Failed With Error"
        });
    }).always(function() {
        $("body").removeClass("busy");
    });
}

function wrapXhrForStandard(xhr) {
   var jqDeferred = jQuery.Deferred();
   xhr.done(function(data) {
       if (data && data.error) {
           jqDeferred.reject(data.error, data.message);
       }
       else {
           jqDeferred.resolve(data && data.result || data);
       }
   }).fail(function(jqXHR, textStatus, ex) {
       jqDeferred.reject("AJAX_ERROR", textStatus);
   }).progress(jqDeferred.notify);
   return jqDeferred.promise();
}

/**
 * Submits a form using jQuery forms plugin but returns a Promise.
 * @param {String|Element} form Selector or element of form to submit.
 * @param {Object} options Options as would be passed to $.ajaxSubmit()
 * @returns {Promise} Promise to be resolved on completion, supports upload progress.
 */
function ajaxSubmitPromise(form, options) {
    var jqDeferred = jQuery.Deferred();
    var opts = $.extend({
        error: jqDeferred.reject,
        success: jqDeferred.resolve,
        uploadProgress: jqDeferred.notify
    }, options);
    $(form).ajaxSubmit(opts);
    return jqDeferred.promise();
}

/** 
 * Autocomplete data source function for use with bootstrap-3-typeahead.
 * Loads character names.
 */
function charNameSource(query, process) {
    $.getJSON("info.srv", {dataset:"charnames", q: query}).then(function(data) {
        process(data.result);
    });
}

/**
 * Autocomplete data source function for use with bootstrap-3-typeahead.
 * Loads account names.
 * Note: Secrecy is not security, so its fine if they know this exists.
 */
function accountNameSource(query, process) {
    $.getJSON("info.srv", {dataset:"accountnames", q:query}).then(function(data) {
        process(data.result);
    });
}

// Shim for console.log to prevent crashing of debugging code
if (!("console" in window)) {
    window.console = {log: function(){}};
}

// Handle AJAX Submit of standard forms.
$(document).on("submit", "form[data-returnto]", function(event) {
    event.preventDefault();
    var $form = $(this);
    var returnTo = $form.data("returnto");
    $form.ajaxSubmit({
        success: function(data) {
            if (data && data.error) {
                alert(data.error + (data.message === data.error ? "" : "\n" + data.message));
                // If error indicates which fields are bad, add error state
                if (data.fields) $.each(data.fields, function() {
                    var $el = $($form.get(0).elements[this]);
                    $el.closest(".form-group").addClass("has-error");
                });
            } else if (returnTo === "none") {
                // Don't navigate, but clear out the errors since none failed.
                $form.find(".has-error").removeClass(".has-error");
            } else if (returnTo === "reload") {
                window.location.reload();
            } else {
                window.location = returnTo;
            }
        }
    });
});

/**
 * Rate limits a function call; converts it into a version that will executes
 * the actual function only once {wait} ms after the last time it is called.
 * @param {type} func
 * @param {type} wait
 * @returns {Function} 
 */
function debounce(func, wait) {
    return function() {
        var that = this, args = [].slice(arguments);
        clearTimeout(func._throttleTimeout);
        func._throttleTimeout = setTimeout(function() {
            func.apply(that, args);
        }, wait);
    };
}

// TODO - Automatic closing of dialog boxes when form submit success
// TODO - Automcatic clearing of dialog box forms when Cancel is pressed (but not auto-dismiss)
// TODO - Better handling of form submission errors - highlight bad fields?

//
// Setup some chat-wide useful things
//
$(document).ready(function() {
    // Text boxes that auto-complete a single character name.
    $(".auto-charname-single").typeahead({source: charNameSource});

    // Text boxes that auto-complete a single account name.
    $(".auto-accountname-single").typeahead({source: accountNameSource});
    
    // Driver code to make button-dropdown based inputs go. (Note, this doesn't support new elements)
    $(".dropdown-menu.combo").on("click", "a", function(evt) {
        var $this = $(this);
        var value = $this.attr("href").substr(1);
        $this.parents(".dropdown-menu").parent()
            .children("input").val(value).end()
            .find(".btn-select-current").html($this.html());
        evt.preventDefault();
    }).each(function() {
        // Initialize the display with initial value (if any)
        var $this = $(this);
        var $selected = $this.find("a[href='#" + $this.prevAll("input").val() + "']");
        if ($selected.length === 1) {
            $this.parent().find(".btn-select-current").html($selected.html());
        }
    });
    
});

